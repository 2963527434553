import { Outlet } from "react-router-dom";
import UxSidebar from "./uxSidebar";
import UxHeader from "./uxHeader";
import UxFooter from "./uxFooter";

export default function UxBaseline({ children }) {
  return (
    <>
      <UxSidebar />
      <div className="app-main w-full flex flex-col">
        <UxHeader />
        <Outlet />
        {children}
        <UxFooter />
      </div>
    </>
  );
}
