import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function UxLoading() {
  return (
    <>
      <div className="fixed w-full h-screen bg-black/75 supports-[backdrop-filter]:bg-black/25 supports-[backdrop-filter]:backdrop-blur z-10 flex">
        <div className=" z-10 px-16 py-12 m-auto relative bg-white dark:bg-gray-900 text-black dark:text-white text-3xl">
          Loading
          <div className="pt-8 text-center">
            <FontAwesomeIcon
              icon="fa-regular fa-snowflake "
              className="fa-spin fa-2xl "
            />
          </div>
        </div>
      </div>
    </>
  );
}
