import { Navigate } from "react-router-dom";
import useAuth from "../providers/authProvider";
import UxBaseline from "../components/ux/uxBaseline";

export const ProtectedBaseline = () => {
  const { token } = useAuth();

  // Check if the user is authenticated
  if (!token) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/account/login" />;
  }

  // If authenticated, render the child routes
  return <UxBaseline />;
};
