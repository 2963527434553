
export default function UxHeader() {
  return (
    <header className=" shadow">
      <nav className="navbar navbar-expand navbar-light navbar h-12 flex-none bg-[#f8f9fa] dark:bg-[#1b2730] justify-start flex items-center relative px-4 py-2">
        <>
          <div className="ml-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex items-center"></div>
            </div>
          </div>
        </>
      </nav>
    </header>
  );
}
