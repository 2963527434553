import { useState } from "react";

export function AuthLogin() {

  let [logo, setLogo] = useState("/assets/logo.png"); // eslint-disable-line no-unused-vars

  return (
    <>
      <div className="py-8 px-16 w-1/3 m-auto bg-white rounded-md">
        <div className="py-2">
          <img
            className="mx-auto my-4 w-[96px] h-[96px]"
            src={logo}
            alt="Logo"
          />
          <h1 className=" text-4xl font-bold w-full text-center">
            Sign in to Handyman
          </h1>
        </div>
        <div className="py-2">
          <label>Email Address:</label>
          <input
            type="email"
            className="px-2 py-2 w-full bg-red  border-black border-2"
            name="email"
            placeholder="john.doe@example.com"
            autoComplete="email"
          />
        </div>
        <div className="py-2">
          <button className="px-2 py-2 w-full bg-black text-white">
            Continue
          </button>
        </div>
      </div>
    </>
  );
}

export function AuthCallback() {
  return (
    <>
      <div>Callback</div>
    </>
  );
}
