import {
  RouterProvider,
  Navigate,
  createBrowserRouter,
} from "react-router-dom";

import useAuth from "../providers/authProvider";
import { ProtectedBaseline } from "./protectedRoute";

import { AuthLogin, AuthCallback } from "../pages/authentication";
import Dashboard from "../pages/dashboard";

export const Routes = () => {
  const { token } = useAuth();

  // Define public routes accessible to all users
  const routesForPublic = [
    { path: "/account/callback", element: <AuthCallback /> },
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedBaseline />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/",
          index: true,
          element: <Navigate to="/dashboard" replace={true} />,
        },
        { path: "dashboard", element: <Dashboard /> },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    { path: "/account/login", element: <AuthLogin /> },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};
