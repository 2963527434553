import ReactDOM from "react-dom/client";
import { Suspense } from "react";

import { AuthProvider } from "./providers/authProvider";
import { Routes } from "./routes";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import "./index.css";

import reportWebVitals from "./reportWebVitals";

import UxLoading from "./components/ux/uxLoading";

const root = ReactDOM.createRoot(
  document.getElementById("root") // as HTMLElement
);

library.add(fab, fas, far);

root.render(
  <AuthProvider>
    <Suspense fallback={<UxLoading />}>
      <Routes />
    </Suspense>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
